import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Sidebar from './SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const UserLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const { t } = useTranslation();

  const handleToggleSidebar = () => {
    setToggled(!toggled);
    setCollapsed(false);
  };

  return (
    <div className="flex h-screen overflow-hidden flex-col md:flex-row">
      <Helmet>
        <title>{t('common.website.title')}</title>
      </Helmet>
      <Sidebar collapsed={collapsed} toggled={toggled} onToggle={handleToggleSidebar} />

      <header className="bg-white shadow-md p-4 md:hidden">
        <button onClick={handleToggleSidebar} className="text-gray-600">
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </header>
      <div className="flex-1 overflow-x-hidden overflow-y-auto">
        <div className="p-6 bg-[#FBFAFC] from-blue-500 to-purple-600 min-h-screen">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
