import { useEffect, Suspense, lazy } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePageTracking from './hooks/usePageTracking';
import UserLayout from './layouts/User';
import PetsPage from './pages/common/Pets';
import LoadingPage from './pages/common/Loading';
import api from './api';
import * as authActions from './redux/authSlice';
import { RootState } from './types/redux/rootState';
import './App.css';

const AuthPage = lazy(() => import('./pages/guest/Auth'));
const HomePage = lazy(() => import('./pages/common/Home'));
const NichesPage = lazy(() => import('./pages/user/niche/Niches'));
const ProductsPage = lazy(() => import('./pages/user/product/Products'));
const WAPage = lazy(() => import('./pages/user/wa/Whatsapp'));
const NotFoundPage = lazy(() => import('./pages/common/NotFound'));
const ProductSearch = lazy(() => import('./pages/user/product/ProductSearch'));

function App() {
  usePageTracking();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticating } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = user !== null;

  // Handle access token expiration
  useEffect(() => {
    const refreshToken = async () => {
      try {
        dispatch(authActions.initialize());

        const storedRefreshToken = localStorage.getItem('refreshToken');
        if (!storedRefreshToken) {
          return;
        }

        const res = await api.auth.refreshToken(storedRefreshToken);
        if (res.status !== 200) {
          throw new Error('Failed to refresh token');
        }

        const data = res.data;

        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);

        // Store tokens in Redux store
        dispatch(
          authActions.setTokens({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken
          })
        );

        // Store user in Redux store
        dispatch(authActions.setUser(data.user));
      } catch (error) {
        console.error('Error during token refresh:', error);

        console.log('logout [Error during token refresh]');
        dispatch(authActions.logout());
      }
    };

    const storedAccessToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');

    if (storedAccessToken && storedRefreshToken) {
      const token = JSON.parse(atob(storedAccessToken.split('.')[1]));
      const exp = token.exp * 1000;

      if (Date.now() >= exp || !user) {
        const rToken = JSON.parse(atob(storedRefreshToken.split('.')[1]));
        const rExp = rToken.exp * 1000;

        if (Date.now() >= rExp) {
          console.error('Refresh token expired');

          dispatch(authActions.logout());

          // Redirect to login page
          navigate('/auth');
        } else {
          refreshToken();
        }
      }
    } else {
      dispatch(authActions.logout());
    }
  }, []);

  const userRoutes = (
    <Route path="" element={<UserLayout />}>
      <Route index element={<>Overview</>} />
      <Route path="niche" element={<NichesPage />} />
      <Route path="niche/:id" element={<HomePage />} />
      <Route path="products" element={<ProductsPage />} />
      <Route path="products/:id" element={<ProductsPage />} />
      <Route path="products/search" element={<ProductSearch />} />
      <Route path="wa" element={<WAPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  );

  const commonRoutes = (
    <>
      <Route path="pets" element={<PetsPage />} />
      <Route path="pets/:action" element={<PetsPage />} />
      {/* <Route path="n/:niche" element={<HomePage />} /> */}
      <Route path="s/:id" element={<HomePage />} />
    </>
  );

  const guestRoutes = (
    <>
      <Route path="/" element={<Navigate to="/auth" replace />} />
      <Route path="auth" element={<AuthPage />} />
      <Route path="*" element={<Navigate to="/auth" replace />} />
    </>
  );

  return (
    <Suspense fallback={<LoadingPage />}>
      <div dir={i18n.dir()}>
        <Routes>
          {isAuthenticating ? (
            <Route path="*" element={<LoadingPage />} />
          ) : (
            /* Show different routes based on authentication state */
            <>
              {isAuthenticated ? userRoutes : guestRoutes}
              {commonRoutes}
            </>
          )}
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
