import { PaginateResult } from '@/types/pagination';
import { Category, Product } from '@/types/product';
import axios from './axios';

const getProducts = async ({ categoryIds, page, query }: { query: string; page: number; categoryIds?: string[] }) => {
  const filters: {
    [key: string]: any;
  } = {};

  if (query && query.trim().length > 0) {
    filters['query'] = query.trim();
  }

  if (page) {
    filters['page'] = page;
  }

  if (categoryIds && categoryIds.length > 0) {
    filters['categoryIds'] = categoryIds.join(',');
  }

  const response = await axios.get<PaginateResult<Product>>('/product/search', {
    params: filters
  });

  return response.data;
};

const getCategories = async () => {
  const response = await axios.get<Category[]>('/product/categories');
  return response.data;
};

const productApi = { getProducts, getCategories };

export default productApi;
