import { createSlice } from '@reduxjs/toolkit';
import { GroupsState } from '@/types/redux/groupsState';

const initialState: GroupsState = {
  imgPath: '/assets/groupLogos',
  groups: [
    {
      id: 'pets/1',
      name: 'דילים סודיים לבעלי חיות מחמד',
      img: 'pets.png',
      inviteCode: 'KNf1tqDjPmU9l9stjMNn3Z'
    },
    {
      id: 'pets/2',
      name: 'דילים סודיים לבעלי חיות מחמד 2',
      img: 'pets.png',
      inviteCode: 'F2uQYd8d3Tq2TvWSKyzt83'
    }
    // Add more groups as needed
  ]
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    addGroup: (state, action) => {
      state.groups.push(action.payload);
    },
    updateGroup: (state, action) => {
      const index = state.groups.findIndex((group) => group.id === action.payload.id);
      if (index !== -1) {
        state.groups[index] = action.payload;
      }
    },
    removeGroup: (state, action) => {
      state.groups = state.groups.filter((group) => group.id !== action.payload);
    }
  }
});

export const { addGroup, updateGroup, removeGroup } = groupsSlice.actions;

export default groupsSlice.reducer;
