import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ModernSnackbarProvider } from './hoc/CustomSnackbar.component';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { store } from './redux/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';

// Sentry initialization
if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_FRONTEND_URL_PATTERN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    tracePropagationTargets: ['localhost', new RegExp(process.env.REACT_APP_FRONTEND_URL_PATTERN)],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found');
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <I18nextProvider i18n={i18n as any}>
    <Provider store={store}>
      <BrowserRouter>
        <ModernSnackbarProvider>
          <App />
        </ModernSnackbarProvider>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
