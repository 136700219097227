import { AxiosResponse } from 'axios';
import axios from 'axios';
import { User } from '@/types/redux/authState';

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  user: User;
}

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

const login = async (provider: string, accessToken: string): Promise<AxiosResponse<AuthResponse>> =>
  authInstance.post(`/auth/${provider}`, { token: accessToken });

const refreshToken = async (refreshToken: string) => authInstance.post('/auth/refresh', { refreshToken });

const authApi = {
  login,
  refreshToken
};

export default authApi;
