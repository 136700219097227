import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [, startTransition] = useTransition();

  const changeLanguage = (lng: string) => {
    startTransition(() => {
      i18n.changeLanguage(lng);
    });
  };

  return (
    <select
      className="w-full bg-gray-50 border rounded-md p-2 mb-4"
      onChange={(e) => {
        changeLanguage(e.target.value);
        e.target.blur();
      }}
      value={i18n.language}
    >
      <option value="he" selected={i18n.language === 'he' ? true : false}>
        עברית
      </option>
      <option value="en" selected={i18n.language === 'en' ? true : false}>
        English
      </option>
    </select>
  );
};

export default LanguageSwitcher;
