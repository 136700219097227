import { Sidebar, Menu, MenuItem, menuClasses, sidebarClasses } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Home, Tag, Package, MessageCircle, LogOut, Menu as MenuIcon } from 'lucide-react';
import * as authActions from '@/redux/authSlice';
import LanguageSwitcher from '@/components/languageSwitcher/LanguageSwitcher.component';

interface UserSidebarProps {
  collapsed: boolean;
  toggled: boolean;
  onToggle: () => void;
}

const UserSidebar = ({ collapsed, toggled, onToggle }: UserSidebarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const menuItems = [
    {
      label: t('sidebar.dashboard'),
      Icon: Home,
      link: '/'
    },
    {
      label: t('sidebar.niches'),
      Icon: Tag,
      link: '/niche'
    },
    {
      label: t('sidebar.products'),
      Icon: Package,
      link: '/products'
    },
    {
      label: t('sidebar.wa'),
      Icon: MessageCircle,
      link: '/wa'
    }
  ];

  const menuItemStyles = {
    root: {
      fontSize: '15px',
      fontWeight: 400
    },
    icon: {
      color: '#44596e',
      [`&.${menuClasses.disabled}`]: {
        color: '#9fb6cf'
      }
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9'
    },
    subMenuContent: ({ level }: { level: number }) => ({
      backgroundColor: level === 0 ? '#f5f5f5' : 'transparent'
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: '#9fb6cf'
      },
      '&:hover': {
        backgroundColor: '#dbf0fe',
        color: '#2563eb'
      },
      '&.active': {
        backgroundColor: '#dbf0fe',
        color: '#2563eb',
        fontWeight: 600
      }
    }
  };

  const isActive = (path: string) => {
    if (path === '/' && location.pathname === '/') return true;
    if (path !== '/' && location.pathname.startsWith(path)) return true;
    return false;
  };

  return (
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={onToggle}
      breakPoint="md"
      backgroundColor="white"
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <div className="p-4 flex items-center justify-between border-b">
        {!collapsed && <h1 className="text-xl font-bold">{t('common.website.title')}</h1>}
        <button onClick={onToggle} className="md:hidden text-gray-600 hover:text-gray-900">
          <MenuIcon size={24} />
        </button>
      </div>

      <div className="flex-1">
        <Menu menuItemStyles={menuItemStyles}>
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              icon={<item.Icon size={20} />}
              component={<Link to={item.link} onClick={onToggle} className={isActive(item.link) ? 'active' : ''} />}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </div>

      <div className="border-t">
        <Menu menuItemStyles={menuItemStyles}>
          <div className="p-4">{!collapsed && <LanguageSwitcher />}</div>
          <MenuItem
            icon={<LogOut size={20} />}
            onClick={async () => {
              await dispatch(authActions.logout());
              navigate('/auth');
            }}
            className="text-red-600"
          >
            {t('sidebar.logout')}
          </MenuItem>
        </Menu>
      </div>
    </Sidebar>
  );
};

export default UserSidebar;
