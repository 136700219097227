import axios from '@/api/axios';
import { PaginateResult } from '@/types/pagination';

const getUserConnections = async ({ page }: { page: number }) => {
  const response = await axios.get<PaginateResult<any>>('/channel/whatsapp/myConnections', {
    params: {
      page
    }
  });
  return response.data;
};

const deleteConnection = async (connectionId: string) => {
  await axios.delete(`/channel/whatsapp/${connectionId}`);
};

const waApi = {
  getUserConnections,
  deleteConnection
};

export default waApi;
