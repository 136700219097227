import React, { forwardRef } from 'react';
import { SnackbarProvider, CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { X, CheckCircle, AlertCircle, Info, AlertTriangle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface CustomSnackbarProps extends Omit<CustomContentProps, 'ref'> {
  dir?: 'rtl' | 'ltr';
}

const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  const { id, message, variant, dir = 'ltr' } = props;
  const { closeSnackbar } = useSnackbar();

  const getIcon = () => {
    switch (variant) {
      case 'success':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'error':
        return <AlertCircle className="w-5 h-5 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
      case 'info':
      default:
        return <Info className="w-5 h-5 text-blue-500" />;
    }
  };

  return (
    <SnackbarContent ref={ref}>
      <div
        className="flex items-center gap-3 min-w-[300px] p-4 rounded-lg shadow-lg bg-white dark:bg-gray-800"
        dir={dir}
      >
        {getIcon()}
        <p className="flex-1 text-sm text-gray-700 dark:text-gray-200">{message}</p>
        <button
          onClick={() => closeSnackbar(id)}
          className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
        >
          <X className="w-4 h-4 text-gray-500 dark:text-gray-400" />
        </button>
      </div>
    </SnackbarContent>
  );
});

CustomSnackbar.displayName = 'CustomSnackbar';

interface SnackbarProviderProps {
  children: React.ReactNode;
}

const ModernSnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const { i18n } = useTranslation();

  const CustomSnackbarWithDir = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => (
    <CustomSnackbar {...props} ref={ref} dir={i18n.dir()} />
  ));

  CustomSnackbarWithDir.displayName = 'CustomSnackbarWithDir';

  return (
    <SnackbarProvider
      Components={{
        success: CustomSnackbarWithDir,
        error: CustomSnackbarWithDir,
        warning: CustomSnackbarWithDir,
        info: CustomSnackbarWithDir
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: i18n.dir() === 'rtl' ? 'left' : 'right'
      }}
      autoHideDuration={4000}
    >
      {children}
    </SnackbarProvider>
  );
};

export { ModernSnackbarProvider };
