import axios from './axios';
import { PaginateResult } from '@/types/pagination';
import { NicheDocument } from '@/types/niche';

export interface NicheFormData {
  _id?: string;
  name: string;
  emoji: string;
  chatIds?: string[];
}

const getUserNiches = async ({
  query,
  page,
  dateRange
}: {
  query?: string;
  page?: number;
  dateRange?: {
    start: string;
    end: string;
  } | null;
}) => {
  const filters: {
    [key: string]: any;
  } = {};
  if (query && query.trim().length > 0) {
    filters['query'] = query.trim();
  }

  if (page) {
    filters['page'] = page;
  }

  if (dateRange) {
    filters['dateRange'] = dateRange;
  }

  const response = await axios.get<PaginateResult<NicheDocument>>('/niche/myNiches', {
    params: filters
  });
  return response.data;
};

const createNiche = async (formData: NicheFormData) => axios.post<NicheDocument>('/niche', formData);

const updateNiche = async (nicheId: string, formData: NicheFormData) =>
  await axios.put<NicheDocument>(`/niche/${nicheId}`, formData);

const deleteNiche = async (nicheId: string) => {
  const response = await axios.delete(`/niche/${nicheId}`);
  return response.status === 204;
};

const getGroups = async (nicheId: string | null) => {
  const data: {
    [key: string]: any;
  } = {};
  if (nicheId) {
    data['nicheId'] = nicheId;
  }
  const response = await axios.get('/niche/groups', {
    params: data
  });
  return response.data;
};

const linkGroups = async (nicheId: string, chatIds: string[]) => {
  const response = await axios.post(`/niche/${nicheId}/linkGroups`, { chatIds });
  return response.data;
};

const unlinkGroup = async (nicheId: string, groupId: string) => {
  const response = await axios.delete(`/niche/${nicheId}/unlinkGroup/${groupId}`);
  return response.status === 204;
};

const nicheApi = {
  getUserNiches,
  createNiche,
  updateNiche,
  deleteNiche,
  getGroups,
  linkGroups,
  unlinkGroup
};

export default nicheApi;
