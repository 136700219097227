import auth from './auth';
import channel from './channel';
import niche from './niche';
import product from './product';

const api = {
  auth,
  channel,
  niche,
  product
};

export default api;
