import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LandingPage from '@/layouts/LandingPage';
import { RootState } from '@/types/redux/rootState';

const Pets = () => {
  const navigate = useNavigate();
  const { groups, imgPath } = useSelector((state: RootState) => state.groups);
  let { action } = useParams();
  const [group, setGroup] = useState(groups.find((group) => group.id === `pets/2`));

  useEffect(() => {
    if (!group) {
      return navigate('/404');
    }
  }, [group, navigate]);

  const otherProps: {
    action?: string;
  } = {};
  if (action) {
    otherProps.action = action;
  }

  return group ? (
    <LandingPage id={group.id} inviteCode={group.inviteCode} img={`${imgPath}/${group.img}`} {...otherProps} />
  ) : null;
};

export default Pets;
