import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from '@/types/redux/authState';

const initialState: AuthState = {
  user: null,
  tokens: {
    accessToken: null,
    refreshToken: null
  },
  isAuthenticating: true
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialize: (state) => {
      state.isAuthenticating = true;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticating = false;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      state.user = null;
      state.tokens = {
        accessToken: null,
        refreshToken: null
      };
      state.isAuthenticating = false;
    }
  }
});

export const { initialize, setUser, setTokens, logout } = authSlice.actions;

export default authSlice.reducer;
